$(document).ready(function () {
	$('.js-video').each(function () {
		$this = $(this);

		let isSamsungBrowser = navigator.userAgent.match(/SAMSUNG|Samsung|SGH-[I|N|T]|GT-[I|N]|SM-[A|N|P|T|Z]|SHV-E|SCH-[I|J|R|S]|SPH-L/i);

		if(isSamsungBrowser) {
			$this.addClass('samsung');
		}

		$this.find('figure').on('click', function (event) {
			const $video = $this.find('video');
			$video.get(0).play();
			$video.attr('controls', '');
			$this.find('figure').addClass('is-active');
			$this.find('.video__title').addClass('is-active');
		});

	});
})